import mynd1 from "./flugeldabladid.jpg";
import mynd2 from "./Lions.jpg";
import mynd3 from "./litlibergthor.jpg";

const Timarit = [
  { img: `${mynd1}`, altText: `Flugeldablaðið`, imgSmall: `${mynd1}` },
  { img: `${mynd2}`, altText: `Lionsblað`, imgSmall: `${mynd2}` },
  { img: `${mynd3}`, altText: `Litli Bergþór Tímarit`, imgSmall: `${mynd3}` }
];
export default Timarit;

import Reikningur1 from "./reikningur_1.JPG";
import Reikningur2 from "./reikningur_2.JPG";
import Reikningur3 from "./reikningur_3.JPG";

const Reikningar = [
  { img: `${Reikningur1}`, altText: `Reikningur sýnishorn`, imgSmall: `${Reikningur1}`},
  { img: `${Reikningur2}`, altText: `Reikningur sýnishorn`, imgSmall: `${Reikningur2}` },
  { img: `${Reikningur3}`, altText: `Reikningur sýnishorn`, imgSmall: `${Reikningur3}` }
];

export default Reikningar;

